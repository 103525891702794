import { graphConfig } from '@Config/authConfig'
import axios from 'axios'

export const getUserDetails = async (accessToken: string | null) => {
	const { data, status, statusText } = await axios.get(
		`${graphConfig.graphMeEndpoint}?$select=${graphConfig.userProfileSelectProperties}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json',
			},
		}
	)
	return {
		data,
		status,
		statusText,
	}
}

export const getUserProfile = async (accessToken: string | null) => {
	const { data, status, statusText } = await axios.get(`${graphConfig.graphMeEndpoint}/photo/$value`, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'image/jpeg',
		},
		responseType: 'arraybuffer',
	})
	return {
		data,
		status,
		statusText,
	}
}
