import { PRIVATE_ROUTES } from './routes'

export const SIDEBAR_TOGGLE = 'is_sidebar_open'

export const SIDE_DRAWER_TOGGLE = 'is_side_drawer_open'

export const THEME_MODE_LIGHT = 'light'
export const THEME_MODE_DARK = 'dark'

export const DRAWER_WIDTH = 270

export enum COOKIE_KEY {
	JWT = 'jwt',
}
export enum SECURE_STORAGE_KEY {
	JWT = 'jwt',
}
export enum QUERY_KEY {
	GET_MY_USER_DETAILS = 'get-my-user-details',
	GET_CHAT_CONVERSION_STREAM = 'get_chat_conversion_stream',
	GET_CHAT_RECENT_CONVERSIONS = 'get_chat_recent_conversions',
	DELETE_CHAT_RECENT_CONVERSION = 'delete_chat_recent_conversion',
	GET_CONVERSATION_BY_ID = 'get_conversation_by_id',
	GET_CONVERSATION_SESSIONS_HISTORY = 'get_conversation_sessions_history',
	GET_POSITIVE_FEEDBACK_QUESTIONS = 'get_positive_feedback_questions',
	GET_NEGATIVE_FEEDBACK_QUESTIONS = 'get_negative_feedback_questions',
	GET_TASK_SESSION_BY_ID = 'get_task_session_by_id',
	GET_TASK_CONTENT_POSITIVE_FEEDBACK_QUESTIONS = 'get_task_content_positive_feedback_questions',
	GET_TASK_CONTENT_NEGATIVE_FEEDBACK_QUESTIONS = 'get_task_content_negative_feedback_questions',
	GET_ALL_FAQS_CATEGORIES = 'get_all_faqs_categories',
	GET_FAQS_QUESTION_ANSWER_BY_CATEGORY = 'get_faqs_question_answer_by_category',
	GET_USER_LOGIN_CONSENT = 'get_user_login_consent',
	GET_DOWNLOAD_FILE_KNOWLEDGE_DISCOVERY = 'get_download_file_knowledge_discovery',
	GET_DOWNLOAD_FILE_TASK_ASSISTANCE = 'get_download_file_task_assistance',
	KD_DOWNLOAD_CONVERSATION = 'kd_download_conversation',
	KD_DOWNLOAD_RESPONSE = 'kd_download_response',
}

export const NO_OF_SUGGESTED_PROMPTS_SHOW = 3

export const NO_OF_SOURCE_DOCUMENT_SHOW = 3

export const MockContactObj = [
	{
		name: 'Sunae Kim',
		title: 'Senior Natural Resources Manager',
		department: 'SG-AFNR',
		image: '/images/key_contact_photo2.png',
		email: 'sunaekim@gmail.com',
	},
	{
		name: 'Krishan Singh',
		title: 'Natural Resources Manager',
		department: 'SG-AFNR',
		image: '/images/key_contact_photo1.png',
		email: 'sunaekim@gmail.com',
	},
	{
		name: 'Yasmin Siddiqi',
		title: 'Director',
		department: 'SG-AFNR',
		image: '/images/key_contact_photo3.png',
		email: 'sunaekim@gmail.com',
	},
	{
		name: 'Yasmin Siddiqi 222',
		title: 'Director',
		department: 'SG-AFNR',
		image: '/images/key_contact_photo3.png',
		email: 'sunaekim@gmail.com',
	},
]

export const MockRelevantKnowledge = [
	{
		title: 'Induction Program for New Staff',
		image: '/images/relevant_knowledge_photo1.png',
	},
	{
		title: 'Induction Program: ADB History Gallery Tour',
		image: '/images/relevant_knowledge_photo2.png',
	},
	{
		title: 'IT Induction Program for New Staff',
		image: '/images/relevant_knowledge_photo3.png',
	},
]

export enum KNOWLEDGE_DIS_PAGE_RIGHT_DRAWER_CONTEXT {
	ALL_SOURCE = 'all-source',
	SOURCE = 'source',
	KEY_CONTACT = 'key-contact',
}

export const NO_OF_RECENT_CONVERSATION_SHOW = 5
export const NO_OF_RECENT_TASKS_SHOW = 5

export const cards = [
	{
		id: 1,
		title: 'Navigate Knowledge',
		description: 'A new way to discover documents and information.',
		image: '/images/bg/card-bg-1.png',
		backgroundColor: 'rgba(65, 190, 232, 0.9)',
	},
	{
		id: 2,
		title: 'Find People',
		description: 'Find ADB staff and consultants with ease.',
		image: '/images/bg/card-bg-1.png',
		backgroundColor: 'rgba(253, 181, 21, 1)',
	},
	{
		id: 3,
		title: 'Supercharge Task',
		description: 'AI Assistant that helps you with document generation.',
		image: '/images/bg/card-bg-1.png',
		backgroundColor: 'rgba(200, 218, 43, 1)',
	},
]

export enum FEEDBACK_TYPE {
	LIKE = 'like',
	DISLIKE = 'dislike',
}

export enum FEEDBACK_MODULE_TYPE {
	KD = 'kn-search',
	TASK = 'task-tor',
}

export const TASK_INPUT_MAIN_TOPIC_INPUT_MAX_LENGTH = 300
export const TASK_INPUT_PURPOSE_INPUT_MAX_LENGTH = 300
export const TASK_INPUT_CONTEXT_INPUT_MAX_LENGTH = 3000
export const KD_FEEDBACK_OPTIONAL_MESSAGE_INPUT_MAX_LENGTH = 3000
export const KD_PROMPT_BAR_INPUT_MAX_LENGTH = 3000
export const KD_FOLLOW_UP_PROMPT_BAR_INPUT_MAX_LENGTH = 3000
export const TASK_FOLLOW_UP_PROMPT_BAR_INPUT_MAX_LENGTH = 3000
export const WORKSPACE_SEARCH_INPUT_MAX_LENGTH = 200
export const FAVOURITES_SEARCH_INPUT_MAX_LENGTH = 200
export const ERROR_MESSAGE_INPUT_MAX_CHARACTER_LIMIT_REACHED =
	'You have reached the maximum character limit of [X] characters.'

export const RIGHT_SIDE_PANEL_WIDTH = 320

export const LOADER_TEXT_TASK_SESSION_CONTENT_RESEARCHING = 'Researching'
export const LOADER_TEXT_TASK_SESSION_CONTENT_ANALYZING = 'Analyzing'
export const LOADER_TEXT_TASK_SESSION_CONTENT_GENERATING = 'Generating'
export const LOADER_TEXT_KNOWLEDGE_CONVERSATION_CONTENT_GENERATION = 'Analyzing...'

export const NO_OF_TASK_SESSION_CONTENT_SOURCE_DOCUMENT_SHOW = 3

export const NO_OF_TASK_SESSION_CONTENT_KEYWORDS_SHOW = 5

export enum RIGHT_DRAWER_OVERLAY_SIZE {
	SMALL = 'small',
	LARGE = 'large',
}

export enum WORKSPACE_PAGE_TAB_ID {
	KNOWLEDGE = 'knowledge',
	TASK = 'task',
}

export const WORKSPACE_PAGE_TABS: IWorkspaceTab[] = [
	{
		id: WORKSPACE_PAGE_TAB_ID.KNOWLEDGE,
		title: 'Knowledge Discovery',
		href: PRIVATE_ROUTES.knowledge,
		color: 'primary',
		variant: 'contained',
		badgeCount: 0,
		buttonText: 'conversation',
	},
	{
		id: WORKSPACE_PAGE_TAB_ID.TASK,
		title: 'Task Assistant',
		href: PRIVATE_ROUTES.task,
		color: 'secondary',
		variant: 'outlined',
		badgeCount: 0,
		buttonText: 'task',
	},
]

export enum STREAM_AI_RESPONSE_FINISH_REASON {
	START = 'start',
	STOP = 'stop',
	ERROR = 'error',
}

export const MOBILE_MAIN_CONTENT_TOP_SPACE = 7

export const IS_HIDE_TASK_SESSION_CONTENT_ADD_DELETE_FILE = true

export const TI_TOR_PURPOSE_INPUT_MAX_LENGTH = 3000
export const TI_TOR_ADD_ROLE_POPUP_ROLE_INPUT_MAX_LENGTH = 300
export const TI_TOR_ADD_ROLE_POPUP_EXP_SKILLS_INPUT_MAX_LENGTH = 80

export const NO_OF_RELEVANT_KNOWLEDGE_DOCUMENTS_TO_SHOW = 3

export enum KC_CHAT_DOCUMENT_TYPE {
	SOURCE_DOCUMENT = 'source-document',
	RELEVANT_DOCUMENT = 'relevant-document',
}

export enum TASK_TOR_PAGE_DIALOG_CONTEXT {
	MODIFY_KEYWORDS = 'modify-keywords',
	FEEDBACK = 'feedback',
	SAVE_TO_SHAREPOINT = 'save-to-sharepoint',
}

export const ERROR_MESSAGE_AUTOCOMPLETE_MAX_OPTIONS_LIMIT_REACHED = "You've reached the maximum number of tags allowed."
export const TASK_INPUT_MAX_NO_OF_EXPERTISE_SKILLS_LIMIT = 10
export const TASK_SESSION_CONTENT_MODIFY_EXPERTISE_SKILLS_LIMIT = 18

export const MOBILE_APP_BAR_HEIGHT = 56

export type T_BUTTON_COLOR = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

export enum FAQS_CATEGORY_LABEL {
	TECH_FAQS = 'tech_faq',
	PROMPT_ENGINEERING = 'prompt_engineering',
	DATA_FAQS = 'data_faq',
	ABOUT_THE_KNOWLEDGE_NAVIGATOR = 'about_the_knowledge_navigator',
}

export const SIDE_BAR_MENU_ITEM_BORDER_RADIUS = 2

export const TIMEOUT_API_RETRY_COUNT = 2
export const TOKEN_EXPIRY_API_RETRY_COUNT = 1
export const SNACK_BAR_TOAST_AUTO_HIDE_DURATION = 5000

export const MIN_LENGTH_WORKSPACE_FAVORITES_SEARCH_BAR = 1

export const RENAME_CONVERSATION_INPUT_MAX_LENGTH = 300
