import { SECURE_STORAGE_KEY, TIMEOUT_API_RETRY_COUNT, TOKEN_EXPIRY_API_RETRY_COUNT } from '@Constants/commons'
import { ERROR_CODE, ERROR_NAME } from '@Constants/errors'
import { acquireNewToken, msalLogout } from '@Libs/msalToken'
import secureLocalStorage from 'react-secure-storage'
import { logAction } from '@Libs/utilities'

type SessionGenerationRequest =
	| IKnowledgeConversationSessionGenerationRequest
	| ITaskTORSessionGenerationRequest
	| ITaskSessionFollowUpChatGenerationRequest

export const fetchAIStreamingResponse = async (
	url: string,
	payload: SessionGenerationRequest,
	signal?: AbortSignal,
	_retryCount = 0
): Promise<ReadableStream<Uint8Array> | undefined> => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
			signal,
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${secureLocalStorage.getItem(SECURE_STORAGE_KEY.JWT)}`,
			},
		})
		return handleTryBlock(url, payload, _retryCount, response, signal)
	} catch (error) {
		return handleCatchBlock(url, payload, _retryCount, error as ISessionStreamAPIError, signal)
	}
}

const handleTryBlock = async (
	url: string,
	payload: SessionGenerationRequest,
	_retryCount: number,
	response: Response,
	signal?: AbortSignal
): Promise<ReadableStream<Uint8Array> | undefined> => {
	if (!response.ok) {
		// Check if the error is due to an expired token (status 401) and retry 1 time(count starts from 0)
		if (response.status === ERROR_CODE.UNAUTHORIZED && _retryCount < TOKEN_EXPIRY_API_RETRY_COUNT) {
			try {
				await acquireNewToken()
				return fetchAIStreamingResponse(url, payload, signal, _retryCount + 1)
			} catch (_) {
				await msalLogout()
				return
			}
		}
		// Check if the error is due to an expired token (status 401) and the request has been retried once(count starts from 0)
		if (response.status === ERROR_CODE.UNAUTHORIZED && _retryCount === TOKEN_EXPIRY_API_RETRY_COUNT) {
			await msalLogout()
			return
		}
		let error: ISessionStreamAPIError = {
			status: response.status,
			statusText: response.statusText,
			message: response.statusText,
		}
		// Check if the response is a 422
		if (response.status === ERROR_CODE.UNPROCESSABLE_ENTITY) {
			const errorData = await response.json()
			error = { ...error, message: errorData.detail }
		}
		throw error
	}

	if (!response.body?.getReader) {
		throw new Error('ReadableStream not supported')
	}
	return response.body
}

const handleCatchBlock = async (
	url: string,
	payload: SessionGenerationRequest,
	_retryCount: number,
	error: ISessionStreamAPIError,
	signal?: AbortSignal
): Promise<ReadableStream<Uint8Array> | undefined> => {
	logAction({
		actionName: 'Fetch Catch Block Error',
		actionType: 'log',
		actionData: {
			prompt: error?.message ?? 'Fetch Catch Block Error',
		},
	})
	// The request was made but no response was received except manual terminate
	// retry 2 times(count starts from 0)
	if (
		_retryCount < TIMEOUT_API_RETRY_COUNT &&
		!(error.code === ERROR_CODE.ABORT_SIGNAL && error.name === ERROR_NAME[ERROR_CODE.ABORT_SIGNAL])
	) {
		return fetchAIStreamingResponse(url, payload, signal, _retryCount + 1)
	}
	throw error
}
