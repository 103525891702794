import { NO_OF_RECENT_CONVERSATION_SHOW, QUERY_KEY } from '@Constants/commons'
import { fetchConversationSessions, kdDownloadConversation } from '@Requests/knowledge.api'
import { useQuery } from '@tanstack/react-query'

export const useFetchRecentConversations = () => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_CHAT_RECENT_CONVERSIONS],
		queryFn: () => fetchConversationSessions(NO_OF_RECENT_CONVERSATION_SHOW, 1),
		select: ({ data }) => data,
	})
}

export const useKDDownloadConversation = (conversationId: string, isEnabled: boolean) => {
	return useQuery({
		queryKey: [QUERY_KEY.KD_DOWNLOAD_CONVERSATION, conversationId],
		queryFn: () => kdDownloadConversation(conversationId),
		select: ({ data, headers }) => ({ data, headers }),
		enabled: !!conversationId && isEnabled,
	})
}